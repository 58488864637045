<template>
  <b-card>
    <header-table title="حول المنارة" @create="onAdd" />
    
    <div>
      <div>
        <data-table ref="estatesTable" :fields="fields" ep="/abouts">
          <template #cell(actions)="row">
            <actions-table
              @delete="onDelete(row.item)"
              @update="onUpdateClicked(row.item)"
            />
          </template>
        </data-table>

      </div>

    </div>
    <form-modal ref="estateModalAboutContent" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>


<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BSkeleton,
  BCollapse,
  VBToggle,
  BForm, BFormGroup, BFormInput, BRow, BCol, BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { heightTransition } from '@core/mixins/ui/transition'
import FormQuill from "@/components/form-quill/index.vue";
import LoaderBtn from "@/components/loader-btn.vue";
import DataTable from "@/components/data-table/index.vue";
import FormModal from "@/components/FormModal.vue";
import HeaderTable from "@/components/data-table/components/header-table.vue";
import ActionsTable from "@/components/data-table/components/actions-table.vue";
export default {
  components: {
    BCard,
    BButton,
    BSkeleton,
    VBTooltip,
    BCollapse,
    VBToggle,
    FormQuill, LoaderBtn, BForm, BFormGroup, BFormInput, BRow, BCol, DataTable, BDropdown,
    BDropdownItem, FormModal,
    HeaderTable,
    ActionsTable
},
  mixins: [heightTransition],
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      activModel: false,
      fields: [
        { key: "title", label: "العنوان" },
        { key: "content", label: "المحتوى" },
        { key: "actions", label: "" }
      ],

      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            id: "first_name",
            label: "العنوان",
            rules: "required",
          },
        },
        content: {
          component: "FormTextarea",
          key: "content",
          attrs: {
            label: "المحتوى",
            rules: "required",
          },
        },
      }
    };
  },
 
  methods: {
    ...mapActions("settings/aboutManaraApp", ["getAbout", "updateAboutContent", "deletAboutContent", "createAboutContent"]),
    onAdd() {
      this.$refs.estateModalAboutContent.init({});
      this.activModel = true;
    },
    onDelete(item) {
      // console.log(id)
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف المحتوى", {
          title: "تأكيد حذف المحتوى",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deletAboutContent({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },
    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        content: form.content,
        title: form.title
      };
      this.$refs.estateModalAboutContent.init(toUpdate);
      this.activModel = true;
    },
    onModalConfirmed(form) {
console.log(form)
      if (form.id) {
        this.updateAboutContent({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createAboutContent({ data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },

  },
  computed: {
    ...mapGetters("settings/aboutManaraApp", ["about", "loading"]),
  },
  watch: {
    about(val) {
      this.aboutText = val[1].content
    }
  },

  created() {
    this.getAbout();
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>